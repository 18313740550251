

import React from "react"


import Layout from "../components/layout"
import Seo from "../components/seo"

function PreparationPage({ location, data }) {

  return (
    <Layout location={location}>
      <Seo title="Preparation Instructions Extreme Automation Workshop" />
      <section className="mt-8 lg:mt-18 mb-20">
        <header>
          <h1>Preparation Instructions</h1>
        </header>
        <article className="pt-16 pb-64 lg:prose-xl prose prose-quoteless prose-code:before:content-none prose-code:after:content-none">

          { /* TODO: add book info: https://developers.google.com/search/docs/advanced/structured-data/book */}

          <blockquote>
            <strong>ℹ️ INFO: Read carefully!</strong>
            <p>During the workshop for practical exercises, you'll need to use your own computer in combination with cloud resources. Please, install the recommended <a href="#tools">tools</a> and make sure that you pass the <a href="#self-check">self-check</a> tests.</p>
          </blockquote>

          <h2 id="tools">Tools</h2>
          <p>Any operating system is allowed, but some installation variations may apply. The following software <strong>needs to be installed locally on your computer</strong>:</p>
          <ul>
            <li><strong>Git</strong> (available through package managers or <a href="https://git-scm.com/download/win">Git for Windows</a> installer). ℹ️ If you never used Git before, it is recommended to watch the introductory videos: <a href="https://www.youtube.com/watch?v=BCQHnlnPusY&list=PLRqwX-V7Uu6ZF9C0YMKuns9sLDzK6zoiV&ab_channel=TheCodingTrain">Git for poets</a>.</li>
            <li><strong>SSH</strong> (it is installed by default on MacOS and Linux-based machines, but for Windows it is recommended to install <em>Git for Windows</em> with a full set of tools)</li>
            <li><strong>AWS CLI</strong>
              <ul>
                <li><a href="https://aws.amazon.com/cli/">Installation guide</a></li>
                <li><a href="https://awscli.amazonaws.com/AWSCLIV2.pkg">Direct link to MacOS installer</a></li>
                <li><a href="https://awscli.amazonaws.com/AWSCLIV2.msi">Direct link to Windows installer</a></li>
              </ul>
            </li>
            <li><strong>Terraform</strong> 1.+ (download <a href="https://www.terraform.io/downloads.html">binary for your platform</a> and put it on your <code>PATH</code>)
              <ul>
                <li><a href="https://releases.hashicorp.com/terraform/1.0.5/terraform_1.0.5_linux_amd64.zip">Direct link to Linux binary</a></li>
                <li><a href="https://releases.hashicorp.com/terraform/1.0.5/terraform_1.0.5_darwin_amd64.zip">Direct link to MacOS binary</a></li>
                <li><a href="https://releases.hashicorp.com/terraform/1.0.5/terraform_1.0.5_windows_amd64.zip">Direct link to Windows binary</a></li>
              </ul>
            </li>
            <li><strong>Kubectl</strong> 1.21.+ (download the binary for your platform and put it on your <code>PATH</code>)
              <ul>
                <li><a href="https://kubernetes.io/docs/tasks/tools/install-kubectl/">Installation guide</a></li>
                <li><a href="https://storage.googleapis.com/kubernetes-release/release/v1.21.0/bin/linux/amd64/kubectl">Direct link to Linux binary</a></li>
                <li><a href="https://storage.googleapis.com/kubernetes-release/release/v1.21.0/bin/darwin/amd64/kubectl">Direct link to MacOS binary</a></li>
                <li><a href="https://storage.googleapis.com/kubernetes-release/release/v1.21.0/bin/windows/amd64/kubectl.exe">Direct link to Windows binary</a></li>
              </ul>
            </li>
            <li><strong>Helm</strong> 3.6.+ (download the <a href="https://github.com/helm/helm/releases/tag/v3.6.3">binary for your platform</a> and put it on your <code>PATH</code>)</li>
            <li><strong>Docker</strong> 20.+ (available through package managers) or <strong>Docker Desktop</strong> (<a href="https://desktop.docker.com/mac/stable/Docker.dmg">MacOS</a> and <a href="https://desktop.docker.com/win/stable/Docker%20Desktop%20Installer.exe">Windows</a> direct download links). ⚠️ If there are <strong>problems</strong> installing Docker on your computer, then a <strong>remote Docker host will be provided during the workshop</strong>, to which you can connect over SSH and use as a way to play with Docker directly.</li>
            <li><strong>Ansible</strong> 2.9 (follow <a href="#ansible-installation">Ansible installation instructions</a> below (especially if you are on Windows), or <a href="https://docs.ansible.com/ansible/2.9/installation_guide/intro_installation.html">general instructions</a> from the official documentation). ⚠️ If there are <strong>problems</strong> installing Ansible on your computer, then a <strong>remote control machine will be provided during the workshop</strong>, to which you can connect over SSH and use as a way to play with Ansible code.</li>
          </ul>

          <h2>IDEs</h2>
          <p>You may use the following (optional) visual tools to help you during the workshop:</p>
          <ul>
            <li><a href="https://code.visualstudio.com/">Visual Studio Code</a></li>
            <li>K8s Lens IDE (follow installation <a href="https://k8slens.dev/">instructions</a>)</li>
          </ul>

          <h2>Windows tools</h2>
          <ul>
            <li>WSL (<a href="https://docs.microsoft.com/en-us/windows/wsl/install-win10">https://docs.microsoft.com/en-us/windows/wsl/install-win10</a>)</li>
            <li>Cmdr (<a href="http://cmder.net/">http://cmder.net/</a>)</li>
            <li>Putty (<a href="http://www.chiark.greenend.org.uk/~sgtatham/putty/">http://www.chiark.greenend.org.uk/~sgtatham/putty/</a>) or Kitty (<a href="http://www.9bis.net/kitty/?page=Download">http://www.9bis.net/kitty/?page=Download</a>)</li>
          </ul>

          <h2 id="self-check">Self-check</h2>
          <h3>Essential tools</h3>
          <p>Check that Git is working from your command line, and you can clone a project from GitLab e.g.:</p>
          <pre>
            <code>
              git --version<br />
              git clone https://gitlab.com/gitlab-org/examples/npm-install
            </code>
          </pre>
          <p>Check that SSH is working from your command line, and you can connect to a remote server:</p>
          <pre>
            <code>
              ssh -V<br />
              ssh git@gitlab.com
            </code>
          </pre>
          <p>You should get output similar to the following image:</p>
          <img src="https://gitlab.com/-/snippets/1983213/raw/master/check1.gif" alt="Essential tools" />

          <h3>Cloud/orchestrator tools</h3>
          <p>Check that cloud/orchestrator tools are installed and working from your command line:</p>
          <pre>
            <code>
              aws --version<br />
              terraform version<br />
              kubectl version --client<br />
              helm version --client
            </code>
          </pre>
          <p>You should get output similar to the following image:</p>
          <img src="https://gitlab.com/-/snippets/1983213/raw/master/check2.gif" alt="Cloud tools" />

          <h3>Optional tools</h3>
          <p>Check that Ansible and Docker are working by running the following commands:</p>
          <pre>
            <code>
              ansible --version<br />
              docker version<br />
            </code>
          </pre>
          <p>You should get output similar to the following image:</p>
          <img src="https://gitlab.com/-/snippets/1983213/raw/master/check3.gif" alt="Optional tools" />

          <h2>Optional tool installation variations</h2>
          <h3 id="ansible-installation">Ansible installation</h3>
          <h4>Ansible on MacOS</h4>
          <p>Install Python (3.5+ will work well) and use <code>pip</code> to install Ansible (you may need to upgrade <code>pip</code> to the latest version):</p>
          <pre>
            <code>
              pip install ansible
            </code>
          </pre>

          <h4>Ansible on Windows</h4>
          <p>There is no supported way of installing Ansible directly on Windows. But there are some options.</p>
          <h5>Option 1: WSL</h5>
          <p>If you have Windows 10, then install Windows Subsystem for Linux with Ubuntu and from within WSL command line run normal Ubuntu installation commands to get Ansible:</p>
          <pre>
            <code>
              sudo apt-add-repository ppa:ansible/ansible<br />
              sudo apt-get update<br />
              sudo apt-get install ansible -y
            </code>
          </pre>

          <h5>Option 2: VirtualBox/Vagrant</h5>
          <p>Another way is to configure a Linux virtual machine on your laptop. There are many ways to do that. One option could be:</p>
          <pre>
            <code>
              vagrant init ubuntu/xenial64<br />
              vagrant up<br />
              vagrant ssh<br />
              sudo apt-add-repository ppa:ansible/ansible<br />
              sudo apt-get update<br />
              sudo apt-get install ansible -y
            </code>
          </pre>

          <h4>Ansible on remote machine</h4>
          <p>If none of the above methods work for you (e.g., you have a very old Windows laptop with no ability to install WSL and with not enough resources to start a VM), then a remote control machine will be provided during the workshop to which you can connect and use as a way to play with Ansible code.</p>

          <h3>Docker installation</h3>
          <h4>Docker on Windows</h4>
          <h5>Option 1: Docker Desktop</h5>
          <p>If you have Windows 10 and Hyper-V enabled then the best option will be installing <a href="https://desktop.docker.com/win/stable/Docker%20Desktop%20Installer.exe">Docker for Windows</a>.</p>

          <h5>Option 2: Docker VM</h5>
          <p>If you have an older version of Windows, then you can use Docker Machine to install a VM with Docker engine inside: <a href="https://docs.docker.com/machine/">https://docs.docker.com/machine/</a>.</p>

          <h4>Docker on MacOS</h4>
          <p>For MacOS the best option is to install <a href="https://download.docker.com/mac/stable/Docker.dmg">https://download.docker.com/mac/stable/Docker.dmg</a></p>

          <h4>Docker on Linux</h4>
          <ul>
            <li>Ubuntu: <a href="https://docs.docker.com/engine/install/ubuntu/">https://docs.docker.com/engine/install/ubuntu/</a></li>
            <li>Debian: <a href="https://docs.docker.com/engine/install/debian/">https://docs.docker.com/engine/install/debian/</a></li>
            <li>CentOS: <a href="https://docs.docker.com/engine/install/centos/">https://docs.docker.com/engine/install/centos/</a></li>
          </ul>

          <h4>Docker on remote machine</h4>
          <p>If none of the above methods work for you, then a remote Docker host will be provided during the workshop to which you can connect and use as a way to play with Docker directly.</p>

        </article>
      </section>
    </Layout>
  )
}

export default PreparationPage
